import { FC } from "react";
import { CardExtratoItemProps } from "../../types";
import { CardExtratoItemIcon } from "../CardExtratoItemIcon";
import CodeUtil from "../../../../../../util/CodeUtil";
import { useAccountBalanceContext } from "../../../../../../contexts/AccountBallanceContext";

export const CardExtratoItem: FC<CardExtratoItemProps> = ({ movimento, tipoExtrato }) => {
    const { showAccountBalance } = useAccountBalanceContext();

    return (
        <div className="flex pr-1.5 my-5 text-xs select-none max-w-full">
            <div className="flex mr-auto space-x-3">
                <CardExtratoItemIcon tipoMovimento={movimento.tipo} />
                <div className="flex flex-col mt-1">
                    <span className="font-medium text-nowrap">{movimento.tipo}</span>
                    <span className="font-light">{movimento.ordem?.observacoesCliente}</span>

                    {showAccountBalance &&
                        <span className={`font-light text-nowrap ${movimento.valor < 0 && 'text-red-700'}`}>{CodeUtil.moneyFormat(movimento.valor, true)}</span>}
                    {!showAccountBalance &&
                        <span className='rounded bg-slate-200 h-3 w-24'></span>}
                </div>
            </div>

            <div className="mt-1 ml-2 flex flex-col">
                <span className="font-medium">{CodeUtil.dateFormat(movimento.data)}</span>
            </div>
        </div>
    );
};