import { FC } from "react";
import { CardAprovacoes } from "../CardAprovacoes";
import { CardExtrato } from "../CardExtrato";
import { ChartHistoricoMovimentacao } from "../ChartHistoricoMovimentacao";
import { Button, Select, TextInput } from "flowbite-react";
import { CodeLabel } from "../../../../components/CodeLabel";
import { useMainDashboard } from "./MainDashboard.hook";
import CodeUtil from "../../../../util/CodeUtil";
import { MainDashboardProps } from ".";
import { SessionManager } from "../../../../util/SessionManager";
import styled from "styled-components";

export const MainDashboard: FC<MainDashboardProps> = (props) => {
    const { clientes, form, setForm, handleFiltrar, filtroExtrato } = useMainDashboard(props);
    const sessionUser = SessionManager.getSession().usuario;

    return (
        <StyledMainDashboard className="">
            <div className="grid grid-cols-12 mb-3 w-max md:w-full md:space-x-3">
                <div className="md:flex col-span-6">
                    <div className="flex-row">
                        <CodeLabel value="Período:" className="mb-1" />
                        <div className="flex items-center gap-x-3">
                            <TextInput
                                type="date"
                                sizing={"sm"}
                                defaultValue={form.periodo[0]}
                                onBlur={(e) => setForm({
                                    ...form,
                                    periodo: [CodeUtil.dateToString(e.target.valueAsDate ?? new Date(Date.now()))!, form.periodo[1]]
                                })} />
                            <span className="text-sm">à</span>
                            <TextInput
                                type="date"
                                sizing={"sm"}
                                defaultValue={form.periodo[1]}
                                onBlur={(e) => setForm({
                                    ...form,
                                    periodo: [form.periodo[0], CodeUtil.dateToString(e.target.valueAsDate ?? new Date(Date.now()))!]
                                })} />
                        </div>
                    </div>

                    <div className="flex items-center align-middle space-x-3 md:ml-6">
                        {!sessionUser?.clienteId &&
                            <div className="my-3 md:my-0 min-w-64 md:min-w-96">
                                <CodeLabel value="Cliente:" className="mb-1" />
                                <Select
                                    sizing={"sm"}
                                    defaultValue={-1}
                                    onChange={(e) => setForm({ ...form, clienteId: Number.parseInt(e.target.value) ?? -1 })}>
                                    <option value={-1}>Todos os clientes</option>
                                    {clientes.map((item, idx) => <option key={idx} value={item.id}>{item.nomeCompleto}</option>)}
                                </Select>
                            </div>}

                        <div className="mt-6">
                            <Button size="sm" gradientMonochrome="cyan" onClick={handleFiltrar}>Filtrar</Button>
                        </div>
                    </div>
                </div>
            </div>

            <ChartHistoricoMovimentacao tipoContrato={[props.tipoContrato]} />

            <div className="mt-6 gap-3 flex flex-wrap place-content-center lg:place-content-stretch">
                <CardExtrato {...filtroExtrato} />
                <CardAprovacoes tipoContrato={[props.tipoContrato]} />
            </div>
        </StyledMainDashboard>
    );
};

const StyledMainDashboard = styled.div`  
    padding: 0 12rem;
`;