import { FC, useState, MouseEvent } from "react";
import { DashboardCard } from "../../../../components/DashboardCard";
import { ShowToast } from "../../../../components/CodeToast";
import { OrdemService } from "../../../../services/OrdemService";
import { FormStateType, OrdemStatus } from "../../../../enums";
import { OrdemModel } from "../../../../models/OrdemModel";
import { Button } from "flowbite-react";
import FlatButton from "../../../../components/FlatButton";
import { CardAprovacoesProps } from "./types";
import { CardAprovacoesFooter } from "./components/CardAprovacoesFooter";
import { useSessionContext } from "../../../../contexts/SessionContext";
import { RiHistoryLine } from "react-icons/ri";
import { AsDefaultResponse, DefaultResponse } from "../../../../services/types";
import { PopupReprovacaoOrdem } from "../PopupReprovacaoOrdem";
import { PopupHistoricoOrdens } from "../PopupHistoricoOrdens";
import useUserRole from "../../../../hooks/UserRoleHook";
import { FiRefreshCcw } from "react-icons/fi";
import { CardAprovacoesTimeline } from "./components/CardAprovacoesTimeline";
import CodeUtil from "../../../../util/CodeUtil";

export const CardAprovacoes: FC<CardAprovacoesProps> = (props) => {
    const [justificativa, setJustificativa] = useState<string>("Cancelada pelo cliente.");
    const [ordens, setOrdens] = useState<OrdemModel[]>([]);
    const [showPopupReprovacao, setShowPopupReprovacao] = useState<boolean>(false);
    const [showPopupHistorico, setShowPopupHistorico] = useState<boolean>(false);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    const currentSession = useSessionContext();
    const currentUserRole = useUserRole();

    const handleClickButtonAprovar = async (e: MouseEvent<HTMLButtonElement>) => await aprovarOrdens();

    const handleClickButtonReprovar = async () => {
        if (currentSession.session?.usuario?.tipoUsuario === "ADMIN") {
            setJustificativa("");
            setShowPopupReprovacao(true);
            return;
        }

        await reprovarOrdens();
    }

    const handleRefreshData = (_e: MouseEvent<SVGAElement>) => setDataLoaded(false);

    const aprovarOrdens = async () => {
        let messages: string[] = [];
        let ordensSelecionadas = ordens.filter(item => item.isSelected);

        for (let i = 0; i < ordensSelecionadas.length; i++) {
            let response = await aprovarOrdem(ordensSelecionadas[i]);
            if (!response.success) messages.push(...response.messages);
        }

        if (messages.length > 0) {
            ShowToast({ message: "Algumas ordens não foram canceladas. Veja mais no console." });
            console.log(messages);
        }
    }

    const aprovarOrdem = async (item: OrdemModel): Promise<DefaultResponse> => {
        try {
            let response = await OrdemService.update(item.id ?? 0, {
                status: OrdemStatus.Executada,
                executadaEm: new Date().toISOString()
            });

            if (response.success) setDataLoaded(false)
            else ShowToast({ message: `${CodeUtil.arrayToStr(response.messages)}` });
            return response;
        } catch (error) {
            console.log(error);
            ShowToast({ message: `${error}` });
            return AsDefaultResponse(String(error), [error]);
        }
    }

    const reprovarOrdens = async () => {
        let messages: string[] = [];
        let ordensSelecionadas = ordens.filter(item => item.isSelected);

        await ordensSelecionadas.forEach(async (item) => {
            let response = await reprovarOrdem(item);
            if (!response.success) messages.push(...response.messages);
        });

        if (messages.length > 0) {
            ShowToast({ message: "Algumas ordens não foram canceladas. Veja mais no console." });
            console.log(messages);
        }
    }

    const reprovarOrdem = async (item: OrdemModel): Promise<DefaultResponse> => {
        try {
            let response = await OrdemService.update(item.id ?? 0, {
                status: OrdemStatus.Cancelada,
                justificativaCancelamento: currentSession.session?.usuario?.tipoUsuario === "CLIENTE" ?
                    "Cancelada pelo cliente" : justificativa,
                canceladaEm: new Date().toISOString()
            });

            if (response.success) setDataLoaded(false);
            return response;
        } catch (error) {
            console.log(error);
            return AsDefaultResponse(String(error), [error]);
        }
    }

    return (
        <>
            <DashboardCard {...props}
                header={<>
                    <h2 className="font-bold">{currentUserRole === "CLIENTE" ? "Minhas Ordens" : "Aprovações"}</h2>
                    <div className="flex ml-auto items-center space-x-4">
                        <FiRefreshCcw className="image-button color-atac-accent w-5 h-5" onClick={handleRefreshData} />
                        <RiHistoryLine className='image-button color-atac-accent w-5 h-5'
                            onClick={() => setShowPopupHistorico(true)} />
                    </div>
                </>}

                footer={
                    <CardAprovacoesFooter hidden={props.readonly} >
                        <div className="flex space-x-2 ml-auto">
                            <FlatButton label={currentUserRole === "CLIENTE" ? "Cancelar" : "Reprovar"}
                                onClick={handleClickButtonReprovar}
                                disabled={!ordens.find(item => item.isSelected)} />
                            {currentSession.session?.usuario?.tipoUsuario === "ADMIN" && 
                                <Button 
                                    gradientMonochrome="cyan"
                                    size="sm"
                                    onClick={handleClickButtonAprovar}
                                    disabled={!ordens.find(item => item.isSelected)}>Aprovar</Button>}
                        </div>
                    </CardAprovacoesFooter>
                }>

                <CardAprovacoesTimeline
                    data={[ordens, setOrdens]}
                    readonly={props.readonly}
                    tipoContrato={props.tipoContrato}
                    dataLoaded={[dataLoaded, setDataLoaded]}
                />

            </DashboardCard>

            {showPopupReprovacao && <PopupReprovacaoOrdem justificativa={[justificativa, setJustificativa]}
                show={showPopupReprovacao}
                state={FormStateType.edit}
                onClose={() => setShowPopupReprovacao(false)}
                onSave={async () => {
                    setShowPopupReprovacao(false);
                    await reprovarOrdens();
                }} />}

            {showPopupHistorico && <PopupHistoricoOrdens show={showPopupHistorico}
                state={FormStateType.view}
                tipoContrato={props.tipoContrato}
                onClose={() => setShowPopupHistorico(false)} />}
        </>
    );
};
