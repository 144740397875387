import { TableHeadCell, TableHeadCellProps } from "flowbite-react"
import { useState } from "react";
import { FaArrowUp, FaArrowDown } from 'react-icons/fa/index'

interface SortableTableHeadCellProps extends TableHeadCellProps {
    title: string;
    isSorted: boolean;
    sortDirection: 'asc' | 'desc';
    onSortColumn?: () => void;
}

const SortableTableHeadCell: React.FC<SortableTableHeadCellProps> = ({ title, isSorted, sortDirection, onSortColumn, ...props }) => {
    const [_sortDirection, _setSortDirection] = useState<'asc' | 'desc'>(sortDirection);

    const onCellClick = () => {
        onSortColumn?.call(this);
        _setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    }

    return (
        <TableHeadCell {...props} onClick={() => onCellClick()}>
            {title}
            {isSorted && _sortDirection === 'asc' ? (<FaArrowUp className="inline ml-2" />) : (<></>)} {isSorted && _sortDirection === 'desc' ? (<FaArrowDown className="inline ml-2" />) : (<></>)}
        </TableHeadCell>
    )
}

export default SortableTableHeadCell;