import { useState, useCallback, useEffect } from "react";
import { ShowToast } from "../../../../../../components/CodeToast";
import { ContratoMovimentacaoModel } from "../../../../../../models/ContratoMovimentoModel";
import { ContratoMovimentacaoServiceProps, ContratoMovimentacaoService } from "../../../../../../services/ContratoMovimentacaoService";
import CodeUtil from "../../../../../../util/CodeUtil";
import { UNKOWN_EXCEPTION } from "../../../../../../util/Constants";
import { CardExtratoTimelineProps } from "../../types";

export const useCardExtratoTimeline = (props: CardExtratoTimelineProps) => {
    const [timeline, setTimeline] = useState<string[]>([]);
    const [extrato, setExtrato] = useState<ContratoMovimentacaoModel[]>([]);
    const [dataLoaded, setDataLoaded] = props.dataLoaded;

    const montarExtrato = useCallback(async () => {
        try {
            if (dataLoaded) return;

            setExtrato([]);
            setTimeline([]);
            setDataLoaded(true);

            const params: ContratoMovimentacaoServiceProps = {
                limit: 0,
                page: 1,
                tipoContrato: props.tipoContrato,
                tipoMovimento: props.tipoMovimento,
                statusContrato: ["Vigente", "Encerrado"],
                dataMovimentoInicio: props.periodo[0],
                dataMovimentoTermino: props.periodo[1],
                clienteId: props.clienteId
            };

            const response = await ContratoMovimentacaoService.get(params);
            if (!response.success) {
                ShowToast({ message: CodeUtil.arrayToStr(response.messages) })
                return;
            }

            const data = response.data as ContratoMovimentacaoModel[];
            setExtrato(data);

            const _timeline = data.map(item => new Date(item.data).toLocaleDateString());
            const _arraySetTimeline = Array.from(new Set<string>(_timeline));
            setTimeline(_arraySetTimeline);
        } catch (error) {
            ShowToast({ message: UNKOWN_EXCEPTION })
            console.log(error);
        }
    }, [props, dataLoaded, setDataLoaded]);

    useEffect(() => {
        montarExtrato();
    }, [montarExtrato]);

    return {
        extrato, timeline
    }
}