export enum FormStateType {
    add = 'add',
    edit = 'edit',
    view = 'view'
}

export enum TipoAutenticacao {
    EMAIL = 0,
    DOCUMENTO = 1,
    NOME_USUARIO = 2

}

export enum TipoDocumento {
    cpf = 'CPF',
    cnpj = 'CNPJ',
    passaporte = 'PASSAPORTE'
}

export enum TipoUsuario {
    ADMIN = 'ADMIN',
    CONSULTOR = 'CONSULTOR',
    CLIENTE = 'CLIENTE',
    CONTADOR = 'CONTADOR'
}

export enum TipoMovimento {
    Aporte = "Aporte",
    SaqueAporte = "Saque Aporte",
    Rendimento = "Rendimento",
    SaqueRendimento = "Saque Rendimento",
    Transferencia = "Transferência",
    Reaporte = "Reaporte",
    CorrecaoSaldo = 'Correção de Saldo'
}

export enum OrdemStatus {
    Pendente = "Pendente",
    Executada = "Executada",
    Cancelada = "Cancelada"
}

export enum TipoExtrato {
    Extrato = "Extrato",
    Rendimento = "Rendimento",
    SaldoContrato = "SaldoContrato"
}