import { Badge, DropdownItem } from 'flowbite-react';
import React, { useCallback, useEffect, useState } from 'react';
import { HiCheck } from 'react-icons/hi';
import { IoMdClose } from 'react-icons/io';
import DataGrid from '../../components/DataGrid';
import Spinner from '../../components/CodeSpinner';
import { ClienteService } from '../../services/ClienteService';
import { ClienteModel, defaultCliente } from '../../models/ClienteModel';
import ProfileImage from '../../util/ProfileImage';
import FlatDrowpdown from '../../components/FlatDropdown';
import { CustomerCTA } from './components/CTA';
import { FormStateType } from '../../enums';
import { ModalProps } from '../../types';
import { ShowToast } from '../../components/CodeToast';
import CustomersModal from './components/Modal';
import { Popup } from '../../components/Popup';
import { UsuarioService } from '../../services/UsuarioService';
import CodeUtil from '../../util/CodeUtil';
import { UNKOWN_EXCEPTION } from '../../util/Constants';

const CustomersPage: React.FC = () => {
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);
  const [dataSource, setDataSource] = useState<ClienteModel[]>([]);

  const [selectedModel, setSelectedModel] = useState<ClienteModel>(defaultCliente);
  const [showPopupDelete, setShowPopupDelete] = useState<boolean>(false);
  const [showPopupResetPassword, setShowPopupResetPassword] = useState<boolean>(false);
  const [modalState, setModalState] = useState<ModalProps>({ show: false, state: FormStateType.view, id: 0 });

  const onFormLoad = useCallback(async () => {
    setIsLoadingData(true);
    ClienteService.get({ page: pageIndex, limit: 100 }).then((response) => {
      setDataSource(response.data ?? []);
      setPageIndex(response.pagination.page);
      setPageCount(response.pagination.pageCount);
    }).finally(() => setIsLoadingData(false));
  }, [setDataSource, setIsLoadingData, pageIndex, setPageIndex, setPageCount]);

  useEffect(() => {
    onFormLoad()
  }, [onFormLoad]);

  const add = () => setModalState({ ...modalState, state: FormStateType.add, show: true });

  const viewOrEdit = (edit: boolean) => {
    if (selectedModel?.id === undefined) {
      ShowToast({ message: `Nenhum registro selecionado para ${edit ? 'edição' : 'visualização'}` });
      return;
    }

    setModalState({ state: edit ? FormStateType.edit : FormStateType.view, show: true, id: selectedModel.id });
  }

  const deleteItem = async () => {
    try {
      let response = await ClienteService.delete(selectedModel!.id!);
      if (!response.success) {
        console.log(response.messages);
        return;
      }

      await onFormLoad();
    } catch (error) {
      console.log(error);
    }
    finally {
      setShowPopupDelete(false);
    }
  }

  const resetCustomerPassword = async () => {
    try {
      if (!selectedModel.usuarioId || selectedModel.usuarioId === 0) {
        ShowToast({ message: "Nenhum cliente selecionado." });
        return;
      }

      const response = await UsuarioService.resetPassword(selectedModel.usuarioId);
      if (!response.success) {
        ShowToast({ message: CodeUtil.arrayToStr(response.messages) });
        return;
      }

      ShowToast({ message: "Um e-mail com a nova senha foi enviado para o cliente." });
    } catch (error) {
      ShowToast({ message: UNKOWN_EXCEPTION });
      console.log(error);
    } finally {
      setShowPopupResetPassword(false);
    }
  }

  const actionButtons = (): JSX.Element => {
    return (
      <CustomerCTA
        modelState={[selectedModel, setSelectedModel]}
        onAdd={() => add()}
        onEdit={async () => viewOrEdit(true)}
        onView={async () => viewOrEdit(false)}
        onDelete={() => setShowPopupDelete(true)}
        onResetPassword={() => setShowPopupResetPassword(true)}>
        <FlatDrowpdown label="Mais Ações" size="sm" color="none">
          <DropdownItem>Relatório de rendimentos</DropdownItem>
          <DropdownItem>Exportar para excel</DropdownItem>
        </FlatDrowpdown>
      </CustomerCTA>
    );
  };

  return (
    <div>
      <Spinner hidden={!isLoadingData} fullPage={true} />

      <div className='mt-5' hidden={isLoadingData}>
        <DataGrid
          multiSelect={false}
          search={true}
          sortedColumn='nome'
          keyColumn='id'
          columns={[
            { propertyName: 'id', title: '#' },
            {
              propertyName: 'bloqueado', title: 'Ativo', columnAlignment: 'center',
              onFormat(value) {
                return (
                  <div className='flex justify-center flex-wrap items-center gap-2'>
                    {(value as boolean) ? <Badge size='sm' color='failure' icon={IoMdClose} /> : <Badge icon={HiCheck} size="sm" color='success' />}
                  </div>
                )
              },
            },
            {
              propertyName: 'nomeCompleto', title: 'Nome', onFormat(value, model) {
                return (<div className='flex'>
                  <img className="w-6 h-6 rounded-full" src={model.foto ?? ProfileImage} alt="" />
                  <div className='pl-2 pt-0.5'>{value}</div>
                </div>)
              }
            },
            { propertyName: 'email', title: 'E-mail' },
            { propertyName: 'grupo.descricao', title: 'Grupo' },
          ]}
          data={dataSource}
          caption={{
            title: 'Clientes',
            resume: 'Listagem de clientes',
            children: actionButtons()
          }}
          pagination={{
            pageCount: pageCount,
            pageIndex: pageIndex,
            limit: 100,
            onPageChange: (pageIndex) => setPageIndex(pageIndex)
          }}
          onRowSelect={(selectedRows) => setSelectedModel(selectedRows[0] as ClienteModel)}
        />
      </div>

      {modalState.show && <CustomersModal
        show={modalState.show}
        id={modalState.id}
        state={modalState.state}
        title="Cadastro de Clientes"
        onClose={() => setModalState({ ...modalState, show: false, id: 0 })}
        onSave={async () => {
          setModalState({ ...modalState, show: false, id: 0 });
          await onFormLoad();
        }}
      />}

      {showPopupDelete && <Popup
        isShowing={showPopupDelete}
        message="Confirma a exclusão do registro selecionado?"
        isDestructive={true}
        onConfirm={async () => await deleteItem()}
        onCancel={() => setShowPopupDelete(false)} />
      }

      {showPopupResetPassword && <Popup
        isShowing={showPopupResetPassword}
        message="Deseja prosseguir com a atualização da senha deste cliente?"
        isDestructive={true}
        onConfirm={async () => await resetCustomerPassword()}
        onCancel={() => setShowPopupResetPassword(false)} />
      }
    </div>
  )
}

export default CustomersPage;