import { AppConfig } from "../env.config";
import { ContratoModel, ContratoStatus, ContratoTipo } from "../models/ContratoModel";
import { ContratoSaldoModel } from "../models/ContratoSaldoModel";
import { BaseService } from "./BaseService";
import { DefaultResponse, ServiceProps } from "./types";

const ENDPOINT = `${AppConfig.ApiURL}/contrato`;

export type ContratoServiceProps = {
    clienteId?: number;
    status?: ContratoStatus[],
    tipo?: ContratoTipo[]
} & ServiceProps;

export type ContratoSaldoServiceProps = {
    contratoId?: number;
    clienteId?: number | null;
    tipoContrato?: ContratoTipo[]
} & ServiceProps;

export class ContratoService extends BaseService {
    public static async get(props: ContratoServiceProps = { limit: 100, page: 1 }): Promise<DefaultResponse<ContratoModel>> {
        let _url = `${ENDPOINT}?limit=${props.limit}&page=${props.page}`;
        if (props.clienteId)
            _url += `&clienteId=${props.clienteId}`;

        props.status?.forEach(status => _url += `&status=${status}`);
        props.tipo?.forEach(tipo => _url += `&tipo=${tipo}`);

        return await this.GET<ContratoModel>(_url);
    }

    public static async getId(id: number): Promise<DefaultResponse<ContratoModel>> {
        return await this.GET<ContratoModel>(`${ENDPOINT}/${id}`);
    }

    public static async getSaldoContrato(props: ContratoSaldoServiceProps): Promise<DefaultResponse<ContratoSaldoModel>> {
        let _url = `${ENDPOINT}/saldo?limit=${props.limit}&page=${props.page}`;

        if (props.clienteId) _url += `&clienteId=${props.clienteId}`;

        if (props.contratoId) _url += `&contratoId=${props.contratoId}`;

        props.tipoContrato?.forEach(tipo => _url += `&tipo=${tipo}`);

        return await this.GET<ContratoSaldoModel>(_url);
    }

    public static async add(model: ContratoModel): Promise<DefaultResponse<ContratoModel>> {
        return await this.POST<ContratoModel>(`${ENDPOINT}`, model);
    }

    public static async update(model: ContratoModel): Promise<DefaultResponse<ContratoModel>> {
        return await this.PATCH<ContratoModel>(`${ENDPOINT}/${model.id}`, model);
    }

    public static async delete(id: number): Promise<DefaultResponse<ContratoModel>> {
        return await this.DELETE(`${ENDPOINT}/${id}`);
    }
}