import { Button } from "flowbite-react"
import { FC } from "react"
import FlatButton from "../FlatButton"
import FlatDrowpdown from "../FlatDropdown"
import { CTAPanelButtonType, CTAPanelProps } from "./types"

const CTAPanel: FC<CTAPanelProps> = (props) => {
    return (
        <div className={`${props.insideDataGridCaption ?? true ? 'mt-3' : 'my-3 p-3 bg-slate-50 rounded-md border border-slate-200 shadow-sm'} flex space-x-2 w-auto`}>
            {
                props.buttons.map((button, key) => {
                    if (!(button.visible ?? true)) return <></>

                    switch (button.type) {
                        case CTAPanelButtonType.CTA:
                        default:
                            return <div key={key}><Button onClick={button.action} disabled={button.disabled} size="sm" gradientMonochrome="cyan">{button.label}</Button></div>

                        case CTAPanelButtonType.FLAT:
                            return <FlatButton key={key} onClick={button.action} label={button.label} disabled={button.disabled} />

                        case CTAPanelButtonType.DESTRUCTIVE:
                            return <FlatButton key={key} isDestructive={true} onClick={button.action} label={button.label} disabled={button.disabled} />

                        case CTAPanelButtonType.DROPDOWN:
                            return (
                                <FlatDrowpdown onClick={button.action} disabled={button.disabled} label={button.label} key={key}>
                                    {button.children}
                                </FlatDrowpdown>
                            )
                    }
                })
            }
        </div>
    )
}

export default CTAPanel