import { createContext, useContext, useState } from "react";
import { ContratoTipo } from "../models/ContratoModel";
import { AccountBalanceProps, AccountBallanceProviderProps } from "./types";

const AccountBalanceContext = createContext<AccountBalanceProps | null>(null);
export const useAccountBalanceContext = () => useContext(AccountBalanceContext) as AccountBalanceProps;

export const AccountBalanceContextProvider: React.FC<AccountBallanceProviderProps> = ({ children }) => {
    const [showBalance, setShowBalance] = useState<boolean>(true);
    const [tipoContrato, setTipoContrato] = useState<ContratoTipo[]>(["Tradicional", "Carteira 2"]);
    const [clienteId, setClienteId] = useState<number | null | undefined>(null);

    const doShowHideAccountBalance = (state: boolean) => setShowBalance(state);
    const doSetTipoContrato = (tipo: ContratoTipo[]) => setTipoContrato(tipo);
    const doSetCliente = (id: number | null | undefined) => setClienteId(id);

    return (
        <AccountBalanceContext.Provider value={{
            showAccountBalance: showBalance,
            showHideAccountBalance: doShowHideAccountBalance,
            tipoContrato: tipoContrato,
            setTipoContrato: doSetTipoContrato,
            clienteId: clienteId,
            setCliente: doSetCliente
        }} >
            {children}
        </AccountBalanceContext.Provider>
    )
}

export default AccountBalanceContext;