import { FC } from "react";
import { CardExtratoTimelineProps } from "../../types";
import { CardExtratoItem } from "../CardExtratoItem";
import PngVoid from "../../../../../../images/void.png";
import { CardExtratoAccountBalance } from "../CardExtratoAccountBalance";
import { useCardExtratoTimeline } from "./CardExtratoTimeline.hook";

export const CardExtratoTimeline: FC<CardExtratoTimelineProps> = (props) => {
    const { extrato } = useCardExtratoTimeline(props);
    return (
        <>
            {props.show && extrato.length === 0 &&
                <div className="p-6 flex-col content-center justify-center text-center">
                    <img alt="void" src={PngVoid} width={100} height={100} className="w-48 h-48 mb-3" />
                    <span className="text-sm font-normal text-slate-700">Está um pouco vazio por aqui.<br /></span>
                </div >}

            {props.show && extrato.length > 0 &&
                <div className="m-0">
                    <CardExtratoAccountBalance
                        dataLoaded={props.dataLoaded}
                        clienteId={props.clienteId}
                        tipoContrato={props.tipoContrato}
                        tipoExtrato={props.tipoExtrato} />

                    {extrato.map((item, idx) =>
                        <CardExtratoItem
                            key={idx}
                            movimento={item}
                            tipoExtrato={props.tipoExtrato}
                            readonly={props.readonly} />)}
                </div>}
        </>
    );
};