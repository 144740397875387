import { useCallback, useEffect, useState } from "react"
import { ClienteModel } from "../../../../models/ClienteModel"
import { ClienteService } from "../../../../services/ClienteService"
import { MainDashboardProps } from "."
import CodeUtil from "../../../../util/CodeUtil"
import { CardExtratoProps } from "../CardExtrato/types"
import { SessionManager } from "../../../../util/SessionManager"
import { useAccountBalanceContext } from "../../../../contexts/AccountBallanceContext"

interface FormFields extends MainDashboardProps {
    readonly?: boolean
    periodo: string[]
    clienteId?: number | null
}

export const useMainDashboard = (props: MainDashboardProps) => {
    const [clientes, setClientes] = useState<ClienteModel[]>([]);
    const [fetchedClientes, setFetchedClientes] = useState<boolean>(false);
    const [extratoLoaded, setExtratoLoaded] = useState<boolean>(false);
    const [rendimentoLoaded, setRendimentoLoaded] = useState<boolean>(false);
    const { setCliente: setAccountBalanceCliente } = useAccountBalanceContext();
    
    const pastMonth = new Date(Date.now());
    pastMonth.setMonth(new Date().getMonth() - 1);
    
    const [form, setForm] = useState<FormFields>({ 
        tipoContrato: props.tipoContrato,
        periodo: [CodeUtil.dateToString(pastMonth, CodeUtil.nowToString())!, CodeUtil.nowToString()],
        clienteId: SessionManager.getSession().usuario?.clienteId ?? null
    });

    const [filtroExtrato, setFiltroExtrato] = useState<CardExtratoProps>({
        tipoContrato: [props.tipoContrato],
        periodo: form.periodo,
        clienteId: form.clienteId,
        extratoLoaded: [extratoLoaded, setExtratoLoaded],
        rendimentoLoaded: [rendimentoLoaded, setRendimentoLoaded]
    });

    const fetchClientes = useCallback(async () => {
        try {
            const response = await ClienteService.get();
            if (!response.success) {
                setClientes([]);
                return;
            }

            setClientes(response.data);
        } finally {
            setFetchedClientes(true);
        }
    }, [])

    useEffect(() => {
        if (!fetchedClientes) fetchClientes();
    }, [fetchClientes, fetchedClientes]);

    const handleFiltrar = (e: React.MouseEvent<HTMLButtonElement> | undefined)  => {
        setFiltroExtrato({
            ...filtroExtrato,
            clienteId: form.clienteId === -1 ? null : form.clienteId,
            periodo: form.periodo
        });

        setAccountBalanceCliente(form.clienteId === -1 ? null : form.clienteId);
        setExtratoLoaded(false);
        setRendimentoLoaded(false);
    }

    return {
        clientes, form, setForm, filtroExtrato, handleFiltrar
    }
}