import { Checkbox, Select, Spinner, TextInput } from "flowbite-react";
import { ChangeEvent, FC, Fragment, useCallback, useEffect, useState } from "react";
import { CodeLabel } from "../../../../../components/CodeLabel";
import { ShowToast } from "../../../../../components/CodeToast";
import { Groupbox } from "../../../../../components/Groupbox";
import { MaskedInput } from "../../../../../components/MaskedInput";
import { FormStateType, TipoDocumento } from "../../../../../enums";
import { ClienteGrupoModel } from "../../../../../models/ClienteGrupoModel";
import { EstadoModel } from "../../../../../models/EstadoModel";
import { MunicipioModel } from "../../../../../models/MunicipioModel";
import { ClienteGrupoService } from "../../../../../services/ClienteGrupoService";
import { ConsultarCepService } from "../../../../../services/ConsutarCepService";
import { EstadoService } from "../../../../../services/EstadoService";
import { MunicipioService } from "../../../../../services/MunicipioService";
import { DefaultCEPResponse } from "../../../../../services/types";
import { findValidationField } from "../../../../../types";
import CodeUtil from "../../../../../util/CodeUtil";
import { CustomersTabProps } from "../../../types";

type DocumentTypeProps = {
    mask: string | null;
    label: string;
}

export const CustomersTabDados: FC<CustomersTabProps> = (props) => {
    const [model, setModel] = props.model;
    const readOnlyForm = props.state === FormStateType.view;
    const [formLoaded, setFormLoaded] = useState<boolean>(false);
    const [buscandoCep, setBuscandoCep] = useState<boolean>(false);
    const [municipios, setMunicipios] = useState<MunicipioModel[]>([]);
    const [estados, setEstados] = useState<EstadoModel[]>([]);
    const [grupos, setGrupos] = useState<ClienteGrupoModel[]>([]);
    const [documentType, setDocumentType] = useState<DocumentTypeProps>({ mask: "###.###.###-##", label: "CPF:" });
    const [errors] = props.errors;

    const consultarCep = (cep: string): void => {
        if ((CodeUtil.removeSpaces(cep)?.length ?? 0) < 8) {
            setBuscandoCep(false);
            return;
        }

        setBuscandoCep(true);
        setTimeout(() => {
            ConsultarCepService.consultar(cep)
                .then((response) => onAfterBuscarCep(response))
                .finally(() => setBuscandoCep(false));
        }, 1500);
    };

    const onAfterBuscarCep = (response: DefaultCEPResponse | null) => {
        if (response === null) return;

        onLoadMunicipios(response.uf ?? "ND")
            .then((_municipios) => {
                let _municipio = _municipios.find((item) => item.descricao?.toLowerCase() === response.localidade?.toLowerCase());

                setModel({
                    ...model,
                    cep: CodeUtil.removeSpecialChars(response.cep) ?? "",
                    logradouro: response.logradouro ?? "",
                    bairro: response.bairro ?? "",
                    municipio: {
                        id: _municipio?.id,
                        descricao: response.localidade ?? "",
                    },
                    uf: {
                        id: CodeUtil.parseInt(response.ddd) ?? 0,
                        sigla: response.uf,
                    }
                });
            });
    }

    const onTipoDocumentoChange = (e: ChangeEvent<HTMLSelectElement>) => {

        setModel({ ...model, tipoDocumento: TipoDocumento.cpf });
        switch (e.currentTarget.value as TipoDocumento) {
            case TipoDocumento.cpf:
            default:
                setDocumentType({ mask: "###.###.###-##", label: "CPF:" });
                break;
            case TipoDocumento.cnpj:
                setModel({ ...model, tipoDocumento: TipoDocumento.cnpj });
                setDocumentType({ mask: "##.###.###/####-##", label: "CNPJ:" });
                break;
            case TipoDocumento.passaporte:
                setModel({ ...model, tipoDocumento: TipoDocumento.passaporte });
                setDocumentType({ mask: null, label: "Passaporte:" });
                break;
        }
    }

    const onLoadEstados = useCallback(async () => {
        try {
            let response = await EstadoService.get({ page: 1, limit: 50 });
            if (!response.success) {
                ShowToast({ message: CodeUtil.arrayToStr(response.messages) });
                setEstados([]);
                return;
            }

            setEstados(response.data);
        } catch (error) {
            setEstados([]);
            console.log(error);
        }
    }, [setEstados]);

    const onLoadMunicipios = async (sigla: string): Promise<MunicipioModel[]> => {

        let response = await MunicipioService.get({
            page: 1,
            limit: 9999999,
            ufSigla: sigla?.trim(),
        });

        if (!response.success) {
            ShowToast({ message: CodeUtil.arrayToStr(response.messages) });
            setMunicipios([]);
            return [];
        }

        setMunicipios(response.data);
        return response.data ?? [];
    };

    const onLoadGrupos = useCallback(async () => {
        try {
            let response = await ClienteGrupoService.get({ page: 1, limit: 50 });
            if (!response.success) {
                ShowToast({ message: CodeUtil.arrayToStr(response.messages) });
                setGrupos([]);
                return;
            }

            setGrupos(response.data);
        } catch (error) {
            setGrupos([]);
            console.log(error);
        }
    }, [setGrupos]);

    const onPageLoad = useCallback(async () => {
        if (!props.show || formLoaded) return;

        await onLoadEstados();
        await onLoadMunicipios(model.uf?.sigla ?? "ND");
        await onLoadGrupos();

        props.onPageLoad?.call(this);
        setFormLoaded(true);
    }, [props.show, onLoadEstados, onLoadGrupos, props.onPageLoad, formLoaded, model]);

    useEffect(() => { onPageLoad() });

    return (
        <Fragment>
            <div className="grid grid-cols-12 space-y-5 px-6">
                <div className="col-span-12">
                    <h2
                        className="ml-auto text-xl font-extrabold text-black/70"
                        hidden={(model.id ?? 0) === 0}
                    >
                        {`ID: #${(model.id ?? 0).toString().padStart(3, "0")}`}
                    </h2>
                </div>

                <div className="form-control col-span-12 md:col-span-6 md:mr-6">
                    <CodeLabel className="mb-1" htmlFor="inputNome" value="Nome:" />
                    <TextInput
                        id="inputNome"
                        type="text"
                        readOnly={readOnlyForm}
                        value={model.nome}
                        helperText={findValidationField(errors, "nome").message}
                        color={findValidationField(errors, "nome").isValid ? "gray" : "failure"}
                        onChange={(e) => setModel({ ...model, nome: e.currentTarget.value })}
                        maxLength={60}
                    />
                </div>

                <div className="form-control col-span-12 md:col-span-6">
                    <CodeLabel className="mb-1" htmlFor="inputSobrenome" value="Sobrenome:" />
                    <TextInput
                        id="inputSobrenome"
                        type="text"
                        readOnly={readOnlyForm}
                        value={model.sobrenome ?? ""}
                        onChange={(e) => setModel({ ...model, sobrenome: e.currentTarget.value })}
                        maxLength={60}
                    />
                </div>

                <div className="form-control col-span-12 md:col-span-4 md:mr-6">
                    <CodeLabel className="mb-1" htmlFor="inputNomeUsuario" value="Login:" />
                    <TextInput id="inputNomeUsuario"
                        value={model.nomeUsuario}
                        maxLength={60}
                        readOnly={readOnlyForm}
                        required={true}
                        helperText={findValidationField(errors, "nomeUsuario").message}
                        color={findValidationField(errors, "nomeUsuario").isValid ? "gray" : "failure"}
                        onChange={(event) => setModel({ ...model, nomeUsuario: event.currentTarget.value })}
                        type="text"
                        placeholder="Nome de usuário ou login" />
                </div>

                <div className="form-control col-span-12 md:col-span-4 md:mr-6">
                    <CodeLabel className="mb-1" htmlFor="selectTipoDocumento" value="Tipo de Documento:" />
                    <Select
                        id="selectTipoDocumento"
                        disabled={readOnlyForm}
                        onChange={onTipoDocumentoChange}
                        value={model.tipoDocumento}>
                        <option key={TipoDocumento.cpf} value={TipoDocumento.cpf}>CPF</option>
                        <option key={TipoDocumento.cnpj} value={TipoDocumento.cnpj}>CNPJ</option>
                        <option key={TipoDocumento.passaporte} value={TipoDocumento.passaporte}>Passaporte</option>
                    </Select>
                </div>

                <div className="form-control col-span-12 md:col-span-4">
                    <CodeLabel className="mb-1" htmlFor="inputDocumento" value={documentType.label} />
                    <MaskedInput
                        id="inputDocumento"
                        type="text"
                        readOnly={readOnlyForm}
                        mask={documentType.mask}
                        pattern="^[0-9]+$"
                        value={model.documento}
                        helperText={findValidationField(errors, "documento").message}
                        color={findValidationField(errors, "documento").isValid ? "gray" : "failure"}
                        onChange={(value) => setModel({ ...model, documento: value })}
                    />
                </div>

                <div className="form-control col-span-12 md:col-span-8 md:mr-6">
                    <CodeLabel className="mb-1" htmlFor="inputEmail" value="E-mail:" />
                    <TextInput id="inputEmail"
                        value={model.email}
                        maxLength={200}
                        readOnly={readOnlyForm}
                        required={true}
                        helperText={findValidationField(errors, "email").message}
                        color={findValidationField(errors, "email").isValid ? "gray" : "failure"}
                        onChange={(event) => setModel({ ...model, email: event.currentTarget.value })}
                        type="email"
                        placeholder="email@email.com.br" />
                </div>

                <div className="form-control col-span-12 md:col-span-4">
                    <CodeLabel className="mb-1" htmlFor="selectGrupo" value="Grupo:" />
                    <Select
                        id="selectGrupo"
                        disabled={readOnlyForm}
                        onChange={(e) => {
                            setModel({
                                ...model,
                                grupo: { ...model.grupo, id: Number(e.currentTarget.value) },
                                clienteGrupoId: Number(e.currentTarget.value)
                            });
                        }}
                        helperText={findValidationField(errors, "grupo").message}
                        color={findValidationField(errors, "grupo").isValid ? "gray" : "failure"}
                        value={model.grupo.id}>
                        <option key={-1} value={-1}>Selecione um grupo</option>
                        {grupos.map((item) => <option key={item.id} value={item.id}>{item.descricao}</option>)}
                    </Select>
                </div>

                <div className="form-control mt-8 col-span-12 flex space-x-2 items-center">
                    <Checkbox
                        id="checkBoxBloqueado"
                        disabled={readOnlyForm}
                        checked={model.bloqueado}
                        onChange={(e) => setModel({ ...model, bloqueado: e.currentTarget.checked })}
                        placeholder="Checked"
                    />
                    <CodeLabel htmlFor="checkBoxBloqueado" value="Cliente bloqueado" />
                </div>

                <div className="col-span-12 mt-5">
                    <Groupbox>
                        <div className="grid grid-cols-12 mt-5">
                            <div className="form-control col-span-4 md:col-span-2">
                                <CodeLabel className="mb-1" htmlFor="inputCEP" value="CEP:" />
                                <div className="flex">
                                    <MaskedInput
                                        id="inputCEP"
                                        type="text"
                                        mask="#####-###"
                                        pattern="^[0-9]+$"
                                        readOnly={readOnlyForm}
                                        value={model.cep ?? ""}
                                        onChange={(value) => {
                                            setModel({ ...model, cep: value });
                                            consultarCep(value);
                                        }}
                                        maxLength={10}
                                    />

                                    <div className={"ml-3 mt-2 text-white"} hidden={!buscandoCep}>
                                        <Spinner size="sm" light={true} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-10"></div>

                            <div className="form-control mt-5 col-span-9 mr-6">
                                <CodeLabel className="mb-1" htmlFor="inputLogradouro" value="Logradouro:" />
                                <TextInput
                                    id="inputLogradouro"
                                    type="text"
                                    readOnly={readOnlyForm}
                                    value={model.logradouro ?? ""}
                                    onChange={(e) => setModel({ ...model, logradouro: e.currentTarget.value })}
                                    maxLength={100}
                                />
                            </div>

                            <div className="form-control mt-5 col-span-3">
                                <CodeLabel className="mb-1" htmlFor="inputLogradouroNumero" value="Número:" />
                                <TextInput
                                    id="inputLogradouroNumero"
                                    type="text"
                                    readOnly={readOnlyForm}
                                    value={model.logradouroNumero ?? ""}
                                    helperText={findValidationField(errors, "logradouroNumero").message}
                                    color={findValidationField(errors, "logradouroNumero").isValid ? "gray" : "failure"}
                                    onChange={(e) => setModel({ ...model, logradouroNumero: e.currentTarget.value })}
                                    maxLength={10}
                                />
                            </div>

                            <div className="form-control mt-5 col-span-12 md:col-span-6 md:mr-6">
                                <CodeLabel className="mb-1" htmlFor="inputBairro" value="Bairro:" />
                                <TextInput
                                    id="inputBairro"
                                    type="text"
                                    readOnly={readOnlyForm}
                                    value={model.bairro ?? ""}
                                    onChange={(e) => setModel({ ...model, bairro: e.currentTarget.value })}
                                    maxLength={80}
                                />
                            </div>

                            <div className="form-control mt-5 col-span-3 md:col-span-2 mr-6">
                                <CodeLabel className="mb-1" htmlFor="selectUF" value="UF:" />
                                <Select id="selectUF" onChange={(e) => {
                                    setModel({ ...model, uf: { ...model.uf, sigla: e.currentTarget.value } });
                                    onLoadMunicipios(e.currentTarget.value)
                                }}
                                    disabled={readOnlyForm}
                                    value={model.uf?.sigla ?? "ND"}>
                                    <option key={'0'} value={'ND'}>ND</option>
                                    {estados.map((estado) => (<option key={estado.sigla} value={estado.sigla}>{estado.sigla}</option>))}
                                </Select>
                            </div>

                            <div className="form-control mt-5 col-span-9 md:col-span-4">
                                <CodeLabel className="mb-1" htmlFor="selectMunicipio" value="Município:" />
                                <Select
                                    id="selectMunicipio"
                                    disabled={readOnlyForm}
                                    onChange={(e) => setModel({
                                        ...model,
                                        municipio: { ...model.municipio, id: Number(e.currentTarget.value) },
                                        municipioId: Number(e.currentTarget.value)
                                    })}
                                    value={model.municipio?.id ?? 0}>
                                    <option key={0} value={0}>Selecione um município</option>
                                    {municipios.map((municipio) => (
                                        <option key={municipio.id} value={municipio.id}>
                                            {municipio.descricao}
                                        </option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </Groupbox>
                </div>
            </div>
        </Fragment>
    );
};