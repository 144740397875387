import { TipoMovimento } from "../enums";
import { AppConfig } from "../env.config";
import { ContratoStatus, ContratoTipo } from "../models/ContratoModel";
import { ContratoMovimentacaoModel } from "../models/ContratoMovimentoModel";
import { ExtratoRelatorioModel } from "../models/ExtratoRelatorioModel";
import CodeUtil from "../util/CodeUtil";
import { BaseService } from "./BaseService";
import { DefaultResponse, ServiceProps } from "./types";

const ENDPOINT = `${AppConfig.ApiURL}/contrato-movimentacao`;

export type ContratoMovimentacaoServiceProps = {
    tipoContrato?: ContratoTipo[],
    tipoMovimento?: TipoMovimento[],
    statusContrato?: ContratoStatus[],
    dataMovimentoInicio?: string,
    dataMovimentoTermino?: string,
    contratoId?: number,
    clienteId?: number | null,
    ordemId?: number,
} & ServiceProps;

export class ContratoMovimentacaoService extends BaseService {
    public static async get(props: ContratoMovimentacaoServiceProps = { limit: 100, page: 1 }): Promise<DefaultResponse<ContratoMovimentacaoModel>> {
        let _url = `${ENDPOINT}?limit=${props.limit}&page=${props.page}`;

        props.tipoContrato?.forEach(tipoContrato => _url += `&tipoContrato=${tipoContrato}`);
        props.tipoMovimento?.forEach(tipoMovimento => _url += `&tipoMovimento=${tipoMovimento}`);
        props.statusContrato?.forEach(statusContrato => _url += `&statusContrato=${statusContrato}`);

        if (!CodeUtil.isNullOrEmpty(props.dataMovimentoInicio)) _url += `&dataMovimentoInicio=${props.dataMovimentoInicio}`;
        if (!CodeUtil.isNullOrEmpty(props.dataMovimentoTermino)) _url += `&dataMovimentoTermino=${props.dataMovimentoTermino}`;

        if (props.ordemId) _url += `&ordemId=${props.ordemId}`;
        if (props.contratoId) _url += `&contratoId=${props.contratoId}`;
        if (props.clienteId) _url += `&clienteId=${props.clienteId}`;

        return await this.GET<ContratoMovimentacaoModel>(_url);
    }

    public static async montarRelatorioMensal(props: ContratoMovimentacaoServiceProps = { limit: 100, page: 1 }): Promise<DefaultResponse<ExtratoRelatorioModel>> {
        let _url = `${ENDPOINT}/relatorio-mensal?limit=0&page=1`;

        props.tipoContrato?.forEach(tipoContrato => _url += `&tipoContrato=${tipoContrato}`);
        props.tipoMovimento?.forEach(tipoMovimento => _url += `&tipoMovimento=${tipoMovimento}`);
        props.statusContrato?.forEach(statusContrato => _url += `&statusContrato=${statusContrato}`);

        if (!CodeUtil.isNullOrEmpty(props.dataMovimentoInicio)) _url += `&dataMovimentoInicio=${props.dataMovimentoInicio}`;
        if (!CodeUtil.isNullOrEmpty(props.dataMovimentoInicio)) _url += `&dataMovimentoInicio=${props.dataMovimentoTermino}`;

        if (props.ordemId !== undefined) _url += `&ordemId=${props.ordemId}`;
        if (props.contratoId !== undefined) _url += `&contratoId=${props.contratoId}`;
        if (props.clienteId !== undefined) _url += `&clienteId=${props.clienteId}`;

        return await this.GET<ExtratoRelatorioModel>(_url);
    }

    public static async getId(id: number): Promise<DefaultResponse<ContratoMovimentacaoModel>> {
        return await this.GET<ContratoMovimentacaoModel>(`${ENDPOINT}/${id}`);
    }

    public static async add(model: ContratoMovimentacaoModel): Promise<DefaultResponse<ContratoMovimentacaoModel>> {
        return await this.POST<ContratoMovimentacaoModel>(`${ENDPOINT}`, model);
    }

    public static async update(id: number, model: unknown): Promise<DefaultResponse<ContratoMovimentacaoModel>> {
        return await this.PATCH<unknown>(`${ENDPOINT}/${id}`, model);
    }

    public static async delete(id: number): Promise<DefaultResponse<ContratoMovimentacaoModel>> {
        return await this.DELETE(`${ENDPOINT}/${id}`);
    }
}