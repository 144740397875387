import { FC } from "react";
import { CardExtatoItemIconProps } from ".";
import { TipoMovimento } from "../../../../../../enums";
import { BiCog, BiDollarCircle, BiMoneyWithdraw, BiTransferAlt, BiTrendingUp } from "react-icons/bi"


export const CardExtratoItemIcon: FC<CardExtatoItemIconProps> = (props) => {
    const icon = (): React.ReactNode => {
        switch (props.tipoMovimento) {
            case TipoMovimento.Aporte: return <BiDollarCircle className="text-2xl text-sky-500" />
            case TipoMovimento.CorrecaoSaldo: return <BiCog className="text-2xl text-amber-500" />
            case TipoMovimento.Reaporte: return <BiTransferAlt className="text-2xl text-indigo-500" />
            case TipoMovimento.Rendimento: return <BiTrendingUp className="text-2xl text-emerald-500" />
            case TipoMovimento.SaqueAporte: return <BiMoneyWithdraw className="text-2xl text-red-500" />
            case TipoMovimento.SaqueRendimento: return <BiMoneyWithdraw className="text-2xl text-purple-500" />
            case TipoMovimento.Transferencia: return <BiTransferAlt className="text-2xl text-slate-500" />
            default: return <BiDollarCircle className="text-2xl text-slate-500" />
        }
    }

    const iconColor = (): string => {
        switch (props.tipoMovimento) {
            case TipoMovimento.Aporte: return "bg-sky-50"
            case TipoMovimento.CorrecaoSaldo: return "bg-amber-50"
            case TipoMovimento.Reaporte: return "bg-indigo-50"
            case TipoMovimento.Rendimento: return "bg-emerald-50"
            case TipoMovimento.SaqueAporte: return "bg-red-50"
            case TipoMovimento.SaqueRendimento: return "bg-purple-50"
            case TipoMovimento.Transferencia: return "bg-slate-50"
            default: return "bg-slate-50"
        }
    }

    return (
        <span className={`flex rounded-full min-w-10 h-10 justify-center items-center ${iconColor()}`}>
            {icon()}
        </span>
    )
}