import { Select, TextInput } from "flowbite-react";
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from "react";
import { CodeLabel } from "../../../../../../components/CodeLabel";
import ModalForm from "../../../../../../components/ModalForm";
import { ModalFormProps } from "../../../../../../components/ModalForm/types";
import { MoneyInput } from "../../../../../../components/MoneyInput";
import { FormStateType } from "../../../../../../enums";
import { ContratoModel } from "../../../../../../models/ContratoModel";
import { findValidationField, FluentValidator } from "../../../../../../types";
import CodeUtil from "../../../../../../util/CodeUtil";

type TabContratosModalProps = {
    model: [ContratoModel, Dispatch<SetStateAction<ContratoModel>>]
} & ModalFormProps

export const TabContratosModal: FC<TabContratosModalProps> = (props) => {
    const [model, setModel] = props.model;
    const readOnly = props.state === FormStateType.view;
    const [errors, setErrors] = useState<FluentValidator[]>([]);
    const [loaded, setLoaded] = useState<boolean>(false);

    const validateForm = (): boolean => {
        setErrors([]);

        if (model.tipo === "Carteira 2" && CodeUtil.isNullOrEmpty(model.vigencia)) setErrors([...errors, { field: "vigencia", isValid: false, message: "A data de vigência deve ser preenchida." }]);
        if (!CodeUtil.isNullOrEmpty(model.vigencia) && !CodeUtil.isValidDate(model.vigencia)) setErrors([...errors, { field: "vigencia", isValid: false, message: "Data de vigência inválida." }]);
        return errors.length === 0;
    }

    const onFormSave = () => {
        if (!validateForm()) return;

        props.onSave?.call(this, undefined);
    }

    const onFormClose = () => {
        setLoaded(false);
        props.onClose?.call(this);
    }

    const onFormLoad = useCallback(async () => {
        if (!props.show || loaded) return;

        setErrors([]);
        setLoaded(true);
    }, [props.show, loaded, setLoaded]);


    useEffect(() => { onFormLoad() });

    return (
        <ModalForm show={props.show}
            title="Detalhes do contrato"
            state={props.state}
            onSave={() => onFormSave()}
            onClose={() => onFormClose()}>

            <div className="grid grid-cols-12 px-6 p-3">
                <div className="form-control mt-3 col-span-8 mr-6">
                    <CodeLabel className="mb-1" htmlFor="selectTipo" value="Tipo do Contrato:" />
                    <Select
                        id="selectTipo"
                        disabled={readOnly}
                        value={model?.tipo}
                        helperText={findValidationField(errors, "tipo").message}
                        color={findValidationField(errors, "tipo").isValid ? "gray" : "failure"}
                        onChange={(e) => {
                            setErrors([]);
                            setModel({
                                ...model,
                                tipo: e.currentTarget.value === "Tradicional" ? "Tradicional" : "Carteira 2",
                                vigencia: e.currentTarget.value === "Tradicional" ? null : model.vigencia
                            });
                        }}
                    >
                        <option value="Tradicional">Tradicional</option>
                        <option value="Carteira 2">Carteira 2</option>
                    </Select>
                </div>

                <div className="form-control mt-3 col-span-4">
                    <CodeLabel className="mb-1" htmlFor="inputVigencia" value="Vigência:" />
                    <TextInput
                        id="inputVigencia"
                        readOnly={readOnly}
                        type="date"
                        disabled={model.tipo === "Tradicional"}
                        value={model.vigencia ?? ""}
                        onChange={(e) => setModel({ ...model, vigencia: e.currentTarget.value })}
                        helperText={findValidationField(errors, "vigencia").message}
                        color={findValidationField(errors, "vigencia").isValid ? "gray" : "failure"}
                    />
                </div>

                <div className="form-control mt-3 col-span-12 md:col-span-4 mr-3">
                    <CodeLabel className="mb-1" htmlFor="inputValorEmCaixa" value="Total Em Caixa:" />
                    <MoneyInput
                        id="inputValorEmCaixa"
                        readOnly={props.state !== FormStateType.add}
                        value={CodeUtil.moneyFormat(model.totalEmCaixa, false)}
                        onChange={(_value, numberValue) => setModel({ ...model, totalEmCaixa: numberValue })}
                    />
                </div>

                <div className="form-control mt-3 col-span-12 md:col-span-4 mr-3">
                    <CodeLabel className="mb-1" htmlFor="inputValorAplicado" value="Total Aplicado:" />
                    <MoneyInput
                        id="inputValorAplicado"
                        readOnly={props.state !== FormStateType.add}
                        value={CodeUtil.moneyFormat(model.totalAplicado, false)}
                        onChange={(_value, numberValue) => setModel({ ...model, totalAplicado: numberValue })}
                    />
                </div>

                <div className="form-control mt-3 col-span-12 md:col-span-4">
                    <CodeLabel className="mb-1" htmlFor="inputRendimento" value="Rendimento Disponível:" />
                    <MoneyInput
                        id="inputRendimento"
                        readOnly={props.state !== FormStateType.add}
                        value={CodeUtil.moneyFormat(model.rendimentoDisponivel, false)}
                        onChange={(_value, numberValue) => setModel({ ...model, rendimentoDisponivel: numberValue })}
                    />
                </div>
            </div>
        </ModalForm>
    );
};