import { Button, Dropdown, Tooltip } from "flowbite-react";
import { FC, Fragment, useCallback, useEffect, useState } from "react";
import CodeStaticList from "../../../../../components/CodeStaticList";
import CodeStaticListItem from "../../../../../components/CodeStaticList/Item";
import { Popup } from "../../../../../components/Popup";
import { FormStateType } from "../../../../../enums";
import { ContratoModel, defaultContratoModel } from "../../../../../models/ContratoModel";
import CodeUtil from "../../../../../util/CodeUtil";
import { CustomersTabProps } from "../../../types";
import { TabContratosModal } from "./Modal";

type ModalContratoType = {
    show: boolean,
    state: FormStateType
}

export const CustomersTabContratos: FC<CustomersTabProps> = (props) => {
    const [model, setModel] = props.model;
    const readOnlyForm = props.state === FormStateType.view;
    const [formLoaded, setFormLoaded] = useState<boolean>(false);
    const [selectedContrato, setSelectedContrato] = useState<ContratoModel>(defaultContratoModel);
    const [selectedContratoIndex, setSelectedContratoIndex] = useState<number | null>(null);
    const [stateModalContrato, setStateModalContrato] = useState<ModalContratoType>({
        show: false,
        state: FormStateType.add,
    });
    const [showPopupDelete, setShowPopupDelete] = useState<boolean>(false);

    const onPageLoad = useCallback(async () => {
        if (!props.show || formLoaded) return;

        props.onPageLoad?.call(this);
        setFormLoaded(true);
    }, [props.show, props.onPageLoad, formLoaded]);

    const updateContratos = () => {
        setStateModalContrato({ ...stateModalContrato, show: false });

        if (stateModalContrato.state === FormStateType.add) {
            setModel({ ...model, contratos: [{ ...selectedContrato, state: 'ADDED' }, ...model.contratos] });
        } else {
            // let index = model.contratos.findIndex((value: ClienteContratoModel) => value.isSelected);
            let index = selectedContratoIndex;
            if (index === null || index < 0) return;

            let updatedContratos = model.contratos;
            updatedContratos[index] = selectedContrato;
            updatedContratos[index].state = 'UPDATED';
            setModel({ ...model, contratos: updatedContratos });
        }
    }

    const cancelarContrato = () => {
        let index = selectedContratoIndex;
        if (index === null || index < 0) return;

        let updatedContratos = model.contratos;
        updatedContratos[index] = selectedContrato;
        updatedContratos[index].state = 'UPDATED';
        updatedContratos[index].status = "Cancelado";

        setModel({ ...model, contratos: updatedContratos });
        setShowPopupDelete(false)
    }

    useEffect(() => { onPageLoad() });

    return (
        <Fragment>
            {model.contratos.length <= 0 ?
                <div className="flex flex-col space-y-3 ml-auto mr-auto p-5 place-items-center">
                    <span className="text-slate-500">Nenhum contrato para este cliente</span>
                    <Button onClick={() => {
                        setSelectedContratoIndex(null);
                        setSelectedContrato(defaultContratoModel);
                        setStateModalContrato({ ...stateModalContrato, show: true, state: FormStateType.add });
                    }}
                        disabled={readOnlyForm}
                        gradientMonochrome="cyan" size="sm">Novo Contrato</Button>
                </div>
                : <></>
            }

            {model.contratos.length > 0 ?
                <div className="space-y-4 px-3">
                    <Button onClick={() => {
                        setSelectedContratoIndex(null);
                        setSelectedContrato(defaultContratoModel);
                        setStateModalContrato({ ...stateModalContrato, show: true, state: FormStateType.add });
                    }}
                        disabled={readOnlyForm}
                        gradientMonochrome="cyan" size="sm">Novo Contrato</Button>

                    <CodeStaticList>
                        {model.contratos.sort((a, b) => b.id - a.id).map((item, idx) =>
                            <CodeStaticListItem key={item.id}>
                                <div className="grid grid-cols-12 items-center text-sm">
                                    <div className="col-span-4 md:col-span-2 flex flex-col">
                                        <span className="text-xs font-semibold text-black/60">Tipo</span>
                                        <span className="pt-1">{item.tipo}</span>
                                    </div>
                                    <div className="col-span-4 md:col-span-2 flex flex-col">
                                        <span className="text-xs font-semibold text-black/60">Saldo</span>
                                        <Tooltip content={<>Total em Caixa: {CodeUtil.moneyFormat(item.totalEmCaixa)}<br />
                                            Total Aplicado: {CodeUtil.moneyFormat(item.totalAplicado)}<br />
                                            Rendimento: {CodeUtil.moneyFormat(item.rendimentoDisponivel)}</>}>
                                            {CodeUtil.moneyFormat(item.totalEmCaixa + item.totalAplicado + item.rendimentoDisponivel)}
                                        </Tooltip>
                                    </div>
                                    <div className="col-span-4 md:col-span-2 flex flex-col">
                                        <span className="text-xs font-semibold text-black/60">Vigência</span>
                                        <strong>{item.vigencia ? CodeUtil.dateFormat(item.vigencia) : "Não Expira"}</strong>
                                    </div>
                                    <div className="mt-3 col-span-4 md:mt-0 md:col-span-2 flex flex-col">
                                        <span className="text-xs font-semibold text-black/60">Status</span>
                                        <span className={`${item.status === "Cancelado" ? "text-red-600 font-semibold" : ""}`}>{item.status}</span>
                                    </div>
                                    <div className="mt-3 col-span-8 md:mt-0 md:col-span-4 mr-4 ml-auto flex flex-col">
                                        {item.status === "Encerrado" ? <></> :
                                            <Dropdown label="Mais Ações" inline={true} gradientDuoTone={"cyanToBlue"} size="">
                                                <Dropdown.Item onClick={() => {
                                                    setSelectedContratoIndex(idx);
                                                    setSelectedContrato(item);
                                                    setStateModalContrato({
                                                        ...stateModalContrato,
                                                        show: true,
                                                        state: readOnlyForm ? props.state : FormStateType.edit
                                                    });
                                                }}>
                                                    {readOnlyForm ? "Consultar" : "Editar"}
                                                </Dropdown.Item>

                                                {item.status === "Cancelado" || readOnlyForm ?
                                                    <></> :
                                                    <Dropdown.Item onClick={() => {
                                                        setSelectedContratoIndex(idx);
                                                        setSelectedContrato(item);
                                                        setShowPopupDelete(true);
                                                    }}>
                                                        <span className="text-red-600">Cancelar</span>
                                                    </Dropdown.Item>
                                                }
                                            </Dropdown>
                                        }
                                    </div>
                                </div>
                            </CodeStaticListItem>)}
                    </CodeStaticList>
                </div> :
                <></>
            }

            <TabContratosModal show={stateModalContrato.show}
                title="Detalhes do contrato"
                state={stateModalContrato.state}
                id={selectedContrato.id ?? 0}
                model={[selectedContrato, setSelectedContrato]}
                onSave={() => updateContratos()}
                onClose={() => setStateModalContrato({ ...stateModalContrato, show: false })} />


            <Popup
                isShowing={showPopupDelete}
                message="Deseja realmente cancelar este contrato?"
                isDestructive={true}
                onConfirm={() => cancelarContrato()}
                onCancel={() => {
                    setSelectedContrato(defaultContratoModel);
                    setShowPopupDelete(false)
                }} />
        </Fragment>
    );
};