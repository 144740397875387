import { FC, useState, MouseEvent } from "react";
import { FiEye, FiEyeOff, FiRefreshCcw } from "react-icons/fi";
import { CodeTabbedTitle } from "../../../../components/CodeTabbedTitle";
import { DashboardCard } from "../../../../components/DashboardCard";
import { useAccountBalanceContext } from "../../../../contexts/AccountBallanceContext";
import { TipoExtrato, TipoMovimento } from "../../../../enums";
import { CardExtratoTimeline } from "./components/CardExtratoTimeline/CardExtratoTimeline";
import { CardExtratoProps } from "./types";

export const CardExtrato: FC<CardExtratoProps> = (props) => {
    const { showAccountBalance, showHideAccountBalance } = useAccountBalanceContext();
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [extratoLoaded, setExtratoLoaded] = props.extratoLoaded;
    const [rendimentoLoaded, setRendimentoLoaded] = props.rendimentoLoaded;

    const handleRefreshData = (_e: MouseEvent<SVGAElement>) => {
        setExtratoLoaded(false);
        setRendimentoLoaded(false);
    }

    return (
        <DashboardCard {...props}
            header={
                <>
                    <CodeTabbedTitle items={["Extrato", "Rendimentos"]} onChange={(index) => setSelectedIndex(index)} />
                    <div className="ml-auto image-button">
                        <div className="flex space-x-4 items-center justify-items-center">
                            <FiRefreshCcw className="color-atac-accent w-5 h-5" onClick={handleRefreshData} />
                            {showAccountBalance ?
                                <FiEye onClick={() => showHideAccountBalance(false)} className='color-atac-accent w-5 h-5' /> :
                                <FiEyeOff onClick={() => showHideAccountBalance(true)} className='color-atac-accent w-5 h-5' />}
                        </div>
                    </div>
                </>}
            footer={<></>}>

            <CardExtratoTimeline show={selectedIndex === 0}
                dataLoaded={[extratoLoaded, setExtratoLoaded]}
                tipoContrato={props.tipoContrato}
                clienteId={props.clienteId}
                periodo={props.periodo}
                tipoExtrato={TipoExtrato.Extrato}
                tipoMovimento={[TipoMovimento.Aporte, TipoMovimento.SaqueAporte, TipoMovimento.Transferencia, TipoMovimento.CorrecaoSaldo]}
                readonly={props.readonly} />

            <CardExtratoTimeline show={selectedIndex === 1}
                dataLoaded={[rendimentoLoaded, setRendimentoLoaded]}
                tipoContrato={props.tipoContrato}
                clienteId={props.clienteId}
                periodo={props.periodo}
                tipoExtrato={TipoExtrato.Rendimento}
                tipoMovimento={[TipoMovimento.Rendimento, TipoMovimento.SaqueRendimento]}
                readonly={props.readonly} />
        </DashboardCard >
    );
};
